import { vue } from '@/main'

export default function () {
  return [
    { key: 'symbol' }, {key: 'sourceName', label: 'sourceName'},
    {
      key: 'group',
      type: 'select',
      props: {
        options: vue.$store.getters['symbol/groups'],
        clearable: true,
        multiple: true
      }
    },
    {
      key: 'types',
      type: 'select',
      props: {
        options: (()=>{
          let tradingModes =  vue.$t('trading.symbolType');
          return Object.keys(tradingModes).map(key => ({ label: tradingModes[key], value: key }))
        })(),
        label: 'label',
        reduce: o=>o.value,
        clearable: true,
        multiple: true
      }
    },
    { key: 'activeOnly', type: 'checkbox' }
  ]
}
