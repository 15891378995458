<template>
  <div>
    <!--Breadcrumb button-->
    <breadcrumb-button>
      <template #beforeDropdown>
        <b-button variant="outline-danger" class="btn-icon" v-b-tooltip v-bind:title="`${$t('operations.restart')} feeds`" @click="tryRestartFeeds">
          <i class="fa-solid fa-rss"></i>
        </b-button>
      </template>
      <template #items>
        <b-dropdown-item @click="openColumnsModal">
          <feather-icon
              icon="CheckSquareIcon"
              size="16"
          />
          <span class="align-middle ml-50">{{ $t('columns.self') }}</span>
        </b-dropdown-item>
      </template>

    </breadcrumb-button>
    <!--Filters-->
    <filter-card  v-model="filter" v-bind:fields="filterFields" search/>
    <!--Table Card-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{ $t('menu.symbols') }}</h5>
      </b-card-header>
      <b-card-body>
        <symbol-table ref="symbolTable" v-bind:filters="filter" v-bind:columns="columns" :has-selection="false"
                        @selected="onSymbolSelected" :lazy="true"/>
      </b-card-body>
    </b-card>
    <!--Modal-->
    <columns-modal ref="columns-modal" column-key="symbol" v-model="columns" :columns-default="['created', 'name', 'sourceName', 'description', 'currency', 'group', 'tradeMode']"
                   v-bind:columns-names="columnsNames"
    />
    <symbol-modal ref="symbol-modal"/>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import BreadcrumbButton from '@/components/widget/breadcrumbButton';
import SymbolSearchRequest from '@/model/symbol/SymbolSearchRequest';
import ColumnsModal from '@/components/modal/columnsModal';
import Symbol from '@/model/symbol/Symbol';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import mixinBase from '@/components/mixin/mixinBase';
import SymbolModal from '@/components/modal/symbolModal';
import FilterCard from '@/components/widget/FilterCard';
import SymbolFilterFields from '@/model/filter/SymbolFilterFields';
import SymbolTable from '@/components/tables/SymbolTable';
import mixinNotifications from '@/components/mixin/mixinNotifications'

export default {
  name: 'SymbolView',
  components: {
    SymbolTable,
    FilterCard,
    SymbolModal,
    ColumnsModal,
    BreadcrumbButton,
  },
  mixins: [mixinBase, mixinNotifications, mixinPermissions],
  data() {
    return {
      columnsNames: ['created', 'name', 'sourceName', 'description', 'currency', 'group', 'tradeMode'],
      filter: SymbolSearchRequest(),
      filterLoaded: false,
      columns: [],
      filterFields: null,
    };
  },
  watch: { },
  async created() {
    this.refreshGroupData()
    this.filterFields = SymbolFilterFields();
  },
  computed: {
    ...mapGetters('data', ['allOrganizations']),
  },
  methods: {
    ...mapActions('symbol', ['refreshData','refreshGroupData', 'restartFeeds']),
    openColumnsModal() {
      this.$refs['columns-modal'].showModal();
    },
    onSymbolSelected(selectedId) {
      // console.log("onSymbolSelected", selectedId)
      this.$refs['symbol-modal'].showModal(selectedId);
    },
    tryRestartFeeds(){
      this.restartFeeds().then(
          ()=> this.showServerSuccessNotification('connectionSuccess', 'feeds'),
          err => this.showServerErrorNotification('connectionError', 'feeds', err)
      )
    }
  }
};
</script>

<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
  height: 10px;
}
</style>
