<template>
  <b-modal no-fade v-model="dialog.show" size="lg" :id="`modal-symbol-${uniqueId}`"
           :hide-footer="true" centered @close="closeModal"
           no-close-on-backdrop
  >
    <template #modal-title>
      <span v-if="loading !== true && error == null && symbol != null">
        {{ symbol.name }}
      </span>
    </template>
    <div style="min-height: 350px">
      <div v-if="loading !== true && error == null">
        <b-tabs v-model="dialog.tab" content-class="" align="center">
          <b-tab :title="`${$t('tabs.symbols.symbol')}`">
            <b-row>
              <b-col cols="6" class="mb-1">
                <label>{{ $t('columns.name') }}</label>
                <b-form-input v-model="symbol.name"></b-form-input>
              </b-col>

              <b-col cols="6" class="mb-1">
                <label>{{ $t('columns.sourceName') }}</label>
                <b-form-input v-model="symbol.sourceName" v-bind:readonly="updateMode"></b-form-input>
              </b-col>

              <b-col cols="12" class="mb-1">
                <label>{{ $t('columns.description') }}</label>
                <b-form-input v-model="symbol.description"></b-form-input>
              </b-col>
              <b-col cols="4" class="mb-1">
                <label>{{ $t('columns.exchange') }}</label>
                <b-form-input v-model="symbol.exchange" v-bind:readonly="updateMode"></b-form-input>
              </b-col>
              <b-col cols="4" class="mb-1">
                <label>{{ $t('columns.type') }}</label>
                <v-select v-model="symbol.type"
                          :options="allSymbolTypesOptions"
                          :clearable="false"
                >

                </v-select>
              </b-col>
              <b-col cols="4" class="mb-1">
                <label>{{ $t('columns.group') }}</label>
                <v-select v-model="symbol.group"
                          :options="allSymbolGroupsOptions"
                          :clearable="false"
                >

                </v-select>
              </b-col>
              <b-col cols="4"  class="mb-1">
                <label>{{ $t('columns.marginCurrency') }}</label>
                <b-form-input v-model="symbol.marginCurrency"></b-form-input>
              </b-col>
              <b-col cols="4" class="mb-1">
                <label>{{ $t('columns.currency') }}</label>
                <b-form-input v-model="symbol.currency"></b-form-input>
              </b-col>

              <b-col cols="4" class="mb-1">
                <label>{{ $t('columns.tradeMode') }}</label>
                <v-select v-model="symbol.tradeMode"
                          :options="tradingModeOptions"
                          :reduce="option => option.value"
                          label="label"
                          :clearable="false"
                >
                </v-select>
              </b-col>
              <b-col cols="4">
                <label>{{ $t('columns.digits') }}</label>
                <b-form-select v-model="symbol.digits" :options="digitOptions"></b-form-select>
              </b-col>


              <b-col cols="4" class="mb-1">
                <label>{{ $t('columns.spread') }}</label>
                <b-form-input v-model="symbol.spread" type="number"></b-form-input>
              </b-col>

              <b-col cols="4" class="mb-1">
                <b-form-checkbox style="margin-top: 2.5em" v-model="symbol.active">{{ $t('columns.active') }}</b-form-checkbox>
              </b-col>


            </b-row>
          </b-tab>
          <b-tab :title="`${$t('tabs.symbols.calculation')}`">
            <b-row>

              <b-col cols="4" class="mb-1">
                <label>{{ $t('columns.contractSize') }}</label>
                <b-form-input v-model="symbol.contractSize" type="number"></b-form-input>
              </b-col>
              <b-col cols="4"  class="mb-1">
                <label>{{ $t('columns.marginPercentage') }}</label>
                <b-input-group size="md" >
                  <b-form-input number type="number" :max="100" v-model="symbol.marginPercentage"></b-form-input>
                </b-input-group>

              </b-col>
              <b-col cols="4" class="mb-1">
                <label>{{ $t('columns.leverage') }}</label>
                <b-form-input v-model="symbol.leverage" type="number"></b-form-input>
              </b-col>
              <b-col cols="4" class="mb-1" v-if="marginCalculationFields.includes('marginInitial')">
                <label>{{ $t('columns.marginInitial') }}</label>
                <b-form-input v-model="symbol.marginInitial" type="number"></b-form-input>
              </b-col>
              <b-col cols="4" class="mb-1" v-if="marginCalculationFields.includes('marginMaintenance')">
                <label>{{ $t('columns.marginMaintenance') }}</label>
                <b-form-input v-model="symbol.marginMaintenance" type="number"></b-form-input>
              </b-col>
              <b-col cols="4"  class="mb-1" v-if="marginCalculationFields.includes('tickSize')">
                <label>{{ $t('columns.tickSize') }}</label>
                <b-form-input v-model="symbol.tickSize"></b-form-input>
              </b-col>
              <b-col cols="4" class="mb-1" v-if="marginCalculationFields.includes('tickValue')">
                <label>{{ $t('columns.tickValue') }}</label>
                <b-form-input v-model="symbol.tickValue" type="number"></b-form-input>
              </b-col>
              <b-col cols="12" class="mb-1">
                <label>{{ $t('columns.profitMode') }}</label>
                <v-select v-model="symbol.profitMode"
                          :options="profitModeOptions"
                          :reduce="option => option.value"
                          label="label"
                          :clearable="false"
                >

                </v-select>
              </b-col>
              <b-col cols="12"  class="mb-1">
                <label>{{ $t('columns.profitMode') }}</label>
                <v-select v-model="symbol.marginMode"
                          :options="marginModeOptions"
                          :reduce="option => option.value"
                          label="label"
                          :clearable="false"
                >

                </v-select>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab :title="`${$t('tabs.symbols.swap')}`">
            <b-row>
              <b-col cols="12"  class="mb-1">
                <label>{{ $t('columns.swapEnable') }}</label>
                <b-form-checkbox v-model="symbol.swapEnable"></b-form-checkbox>
              </b-col>
              <b-col cols="6" class="mb-1">
                <label>{{ $t('columns.swapLong') }}</label>
                <b-form-input v-model="symbol.swapLong" type="number" ></b-form-input>
              </b-col>
              <b-col cols="6" class="mb-1">
                <label>{{ $t('columns.swapShort') }}</label>
                <b-form-input v-model="symbol.swapShort" type="number"></b-form-input>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>

      </div>

      <!-- Add loading and error handling for other cases as needed -->
    </div>
    <div class="text-center mt-2" >
      <b-button class="d-inline-block" block size="sm" variant="primary" @click="save">{{ $t('operations.save') }}</b-button>&nbsp;
    </div>
  </b-modal>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import { mapActions } from 'vuex';

export default {
  name: 'symbolModal',
  components: {},
  mixins: [mixinBase, mixinNotifications],
  data: () => ({
    symbol: {},
    dialog: {
      show: false,
      tab: 0,

    },
    digitOptions: Array.from({ length: 5 }, (_, index) => ({
      text: (index + 1).toString(),
      value: index + 1
    })),
    uniqueId: 0,
    loading: true,
    error: null,
    id: null,
    isNewSymbol: false,
  }),
  created() {
  },
  computed:{
    updateMode(){
      return this.id != null;
    },
    profitModeOptions(){
      let profit =  this.$t('trading.profitCalculation');
      return Object.keys(profit).map(key => ({ label: profit[key], value: key }))
    },
    marginModeOptions(){
      let profit =  this.$t('trading.marginCalculation');
      return Object.keys(profit).map(key => ({ label: profit[key], value: key }))
    },
    tradingModeOptions(){
      let tradingModes =  this.$t('trading.tradingMode');
      return Object.keys(tradingModes).map(key => ({ label: tradingModes[key], value: key }))
    },
    //TODO: Recreate
    allSymbolTypesOptions() {
      let tradingModes =  this.$t('trading.symbolType');
      return Object.keys(tradingModes).map(key => ({ label: tradingModes[key], value: key }))
    },
    allSymbolGroupsOptions() {
      return this.$store.getters['symbol/groups'];
    },
    marginCalculationFields(){
      if(this.symbol.marginMode === 'MARGIN_CALC_CFD_INDEX'){
        return ['tickSize', 'tickValue']
      }
      if(this.symbol.marginMode === 'MARGIN_CALC_FUTURES'){
        return ['marginInitial', 'marginMaintenance']
      }
      return [];
    }
  },
  watch: {

  },

  methods: {
    ...mapActions('symbol', ['getSymbolById','updateSymbol','createSymbol']),

    showModal(id) {
      this.id = id;
      this.loading = true;
      this.dialog.show = true;
      this.dialog.tab = 0;
      this.isNewSymbol = this.id == null || this.id === 0;
      if (!this.isNewSymbol) {
        this.refreshData();
      } else {
        this.symbol = {};
        this.error = null;
        this.loading = false;
      }
      this.pushState({
        symbolId: id,
        tab: 0,
      });
    },
    closeModal() {
      this.dialog.show = false;
      this.symbol = {};
      this.onDialogClose();
    },
    onDialogClose() {
      this.id = null;
      this.pushState({
        symbolId: null,
        tab: null,
      });
    },
    refreshData() {
      let $this = this;
      this.getSymbolById(this.id)
          .then(symbol => {
            $this.symbol = { marginPercentage:1.0, ...symbol };
            $this.error = null;
            $this.loading = false;
          }, err => {
            $this.error = $this.getErrorDescription(err);
            $this.loading = false;
          });
    },
    async save() {
      console.log('symbol:',this.symbol);
      if (this.symbol.name == null) {
        await this.addSymbol();
      } else {
        await this.modifySymbol();
      }
    },
    async addSymbol() {
      return this.showServerErrorNotification('addDataError','symbols')
      try {
        await this.createSymbol({ symbol: this.symbol })
        this.showServerSuccessNotification('addDataSuccess','symbols')
        this.$emit('input', this.symbol)
        this.refreshTables();
      } catch (err) {
        this.showServerErrorNotification('addDataError','symbols', err)
      }
    },
    async modifySymbol() {
      try {
        await this.updateSymbol({ symbol: this.symbol, id: this.symbol.id })
        this.showServerSuccessNotification('updateDataSuccess','symbols')
        this.$emit('input', this.symbol)
        this.refreshTables();
        this.closeModal();
      } catch (err) {
        this.showServerErrorNotification('fetchDataError','symbols', err)
      }
    },

    onSubmitted(server) {
      if (server.id != null) {
        this.selected = server.id;
      }
      this.refreshData();
      this.dialog.tab = 0;
    }
  }
};
</script>

<style scoped>

</style>
